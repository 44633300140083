@media (max-width: 992px) {
  .heroimage {
    width: 300px;
  }

  .descriptionHero {
    width: 400px !important;
  }
}

@media (max-width: 768px) {
  .descriptionHero {
    width: 300px !important;
    font-size: 17px !important;
  }
}

@media (max-width: 768px) {
  .herosectionContent {
    flex-direction: column-reverse;
    .heroimage {
      margin: auto;
      width: 440px;
    }
    .descriptionHero {
        margin: auto;
    }
  }
  .anayses {
    font-size: 18px !important
  }
  .analyseseTitle {
    font-size: 13px;
  }
}


.herosectionContent {
  position: relative;
  height: 100vh;
  overflow: hidden;
  background-color: #000000c8;
}

.video-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: -1;
}

.content {
  /* display: flex; */
  position: relative;
  z-index: 1;
  padding-top: 200px;

  height: 100%;
}
.btn_hero_section_header {
  transition: .5s;
}

.btn_hero_section_header:hover {
  background: transparent !important;
  border: 5px solid #3c096c !important;
}