@media (max-width: 800px) {
  .bannerHowWeAre {
    flex-wrap: wrap;
    justify-content: center !important;
    .title {
      text-align: center;
    }
    .description {
      margin: auto;
      width: 100% !important;
      text-align: center;
    }
    .btnHowWeAre {
      width: fit-content;
      margin: auto;
    }
    .imageHowWeAreBanner {
        display: flex;
        width: 100%;
        justify-content: center;
    }
  }
}
