@media (max-width: 991px) {
  .imgageServsesStyles {
    width: 300px;
  }
}
@media (max-width: 767px) {
  .box_servses_section {
    flex-direction: column-reverse;
  }
  .imgageServsesStyles {
    width: 250px;
    display: flex;
    margin: auto;
  }
}
