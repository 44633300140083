@media (max-width: 993px) {
  .projectBox_Content {
    flex-direction: column-reverse;
  }
  .projectBox_ContentImg {
    width: 300px;
  }
  .textProjectTitle {
    width: 100% ;
    display: flex;
  }
  .btnProjectContent{
    padding-inline: 10px;
    font-size: 13px !important;
  }
}


@media (max-width: 400px) {
    .btnsProjects {
        flex-direction: column;
    }
    .btnsProjects {
        align-items: flex-start;
    }
}