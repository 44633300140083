.link_navbar_toStyles {
  position: relative;
  overflow: hidden;
}

.link_navbar_toStyles::after {
  content: "";
  position: absolute;
  background: #fbd433;
  width: 70%;
  height: 3px;
  bottom: 0;
  left: -50%;
  transform: translateX(-50%);
  transition: 0.5s;
  border-radius: 20px;
}

.link_navbar_toStyles:hover {
  color: #fbd433 !important;
  &::after {
    left: 50%;
  }
}
