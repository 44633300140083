@media (max-width: 805px) {
  .imageContactForm {
    width: 300px !important;
  }
}
@media (max-width: 650px) {
  .contactForm {
    flex-direction: column;
  }
  .contactsContent {
    margin-top: 0px !important;
  }

  .titleForm {
    margin-top: 50px;
  }
}
