@media (max-width: 992px) {
  .imageBanner_serv_ {
    width: 400px;
  }

  .description_banner_serv {
    font-size: 18px !important;
  }
}

@media (max-width: 780px) {
  .imageBanner_serv_ {
    width: 300px;
  }
}

@media (max-width: 768px) {
  .bannerServ_contaioner {
    flex-direction: column;
  }
}