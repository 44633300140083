.Footer {
  /* background-color: var(--mainColor); */
  position: relative;
  padding-top: 100px;

  .footerHead {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 50px;
    margin-bottom: 20px;
  }
  .pagesLinks {
    flex-direction: column;
    display: flex;
  }

  .bottomCopyRight {
    color: rgb(255, 255, 255, 50%);
    padding-block: 10px;
  }

  .copyRight {
    margin: 0;
  }

  .topLine {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
